import { gql, useQuery } from '@apollo/client';

import { FeaturedEvents } from '~/gql/codegen/FeaturedEvents';

const GetFeaturedEventsQuery = gql`
  query FeaturedEvents {
    all_featured_events(limit: 1) {
      items {
        eventsConnection(limit: 30) {
          edges {
            node {
              ... on CompetitionProgram {
                title
                name
                description
                end
                external_url
                is_visible
                start
                location {
                  arenas_slugs
                  info
                }
                system {
                  uid
                }
                price {
                  amount_cents
                  price_description
                  price_type
                  price_volume
                }
                event_typesConnection {
                  edges {
                    node {
                      ... on CompetitionEventType {
                        title
                        value
                      }
                    }
                  }
                }
                games {
                  all_games
                  gamesConnection(limit: 30) {
                    edges {
                      node {
                        ... on CompetitionGame {
                          title
                          imageConnection {
                            edges {
                              node {
                                url
                                system {
                                  uid
                                }
                              }
                            }
                          }
                          system {
                            uid
                          }
                        }
                      }
                    }
                  }
                }
                imageConnection {
                  edges {
                    node {
                      url
                      system {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function useFeaturedEventsGql() {
  return useQuery<FeaturedEvents>(GetFeaturedEventsQuery);
}
