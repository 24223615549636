import { isEmpty, remove } from 'lodash';
import { GetStaticProps } from 'next';

import { GetHomePage } from '~/apis';
import { ContentStackV2 } from '~/components/static-content/ContentStackV2';
import { arenasQuery, featuredNewsItemsQuery, homePageQuery } from '~/hooks/api';
import { isEnabledFeature } from '~/services/featureFlags';
import { Box, FlexLayout, MaxWidthWrapper, Text } from '~/ui';
import { serverSidePrefetch } from '~/utils/prefetch';

import { Carousel } from './Carousel';
import { useFeaturedEvents } from './hooks';

export const getStaticProps: GetStaticProps = async () => {
  // Commenting the translations out since they block ISR.
  // Leaving this to be resolved in the future.
  // to get `locale` prop add `{ locale }: { locale: string }` to `getStaticProps` args
  // const serverSideTranslationsProps = await serverSideTranslations(locale, ['common', 'homepage']);

  const serverSidePrefetchProps = await serverSidePrefetch(
    (client) => arenasQuery.prefetch({}, client),
    (client) => featuredNewsItemsQuery.prefetch({}, client),
    (client) => homePageQuery.prefetch({}, client),
  );
  return {
    props: {
      ...serverSidePrefetchProps,
      // ...serverSideTranslationsProps,
    },
    revalidate: 60,
  };
};

export const HomePage = () => {
  const { data: homeData } = homePageQuery.use({});
  const { data: featuredEvents } = useFeaturedEvents();

  if (!homeData) {
    return null;
  }

  const { hero, restComponents } = extractHeroData(homeData[0][0]);

  return (
    <Box>
      {hero && <ContentStackV2 data={hero[1]} name={hero[0]} />}
      <Box pt={8}>
        {restComponents.map(([name, data]) => {
          return <ContentStackV2 data={data} key={name} name={name} />;
        })}
      </Box>
      {isEnabledFeature('competitions') && !isEmpty(featuredEvents) && (
        <MaxWidthWrapper>
          <FlexLayout flexDirection="column" pb={7} px={[4, 4, 0]} space={5}>
            <Text variant="display-heading-m">Featured Events</Text>
            <Carousel data={featuredEvents} />
          </FlexLayout>
        </MaxWidthWrapper>
      )}
    </Box>
  );
};

function extractHeroData(data: GetHomePage.RootObject) {
  const components = Object.entries(data);
  const [hero] = remove(components, ([name]) => name === 'home_page_hero');

  return { hero, restComponents: components };
}
