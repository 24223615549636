import '@splidejs/splide/css/core';

import { SplideSlide } from '@splidejs/react-splide';

import { HoverableIcon, TextIconBox } from '~/components/shared';
import { withHref } from '~/hocs';
import { ProgramCardProps } from '~/pages-components/CompetitionProgramsPage/ProgramCard/ProgramCard';
import { formatProgramDate } from '~/pages-components/CompetitionProgramsPage/utils';
import { FlexLayout, Image, Text } from '~/ui';

const PureCarouselItem: React.FC<{ program: ProgramCardProps }> = ({ program }) => {
  const { image, title, location, startDate, endDate, price } = program;

  return (
    <FlexLayout flexDirection="column" sx={{ minHeight: '410px' }}>
      <Image src={image.url} sx={{ height: '194px', objectFit: 'contain' }} />
      <FlexLayout flexDirection="column" p={4} space={4} sx={{ flex: 1 }}>
        <Text variant="title-m">{title}</Text>
        <FlexLayout flexDirection="column" space={3} sx={{ marginTop: 'auto' }}>
          <FlexLayout space={2} style={{ position: 'relative' }}>
            <TextIconBox
              icon="gamepadLocationPin"
              sx={{ icon: { display: 'flex' } }}
              text={location.name}
              variant="text-s"
            />
            {location.info && <HoverableIcon position="top" text={location.info} />}
          </FlexLayout>
          <TextIconBox
            icon="calendar"
            sx={{ icon: { display: 'flex' } }}
            text={formatProgramDate(startDate, endDate)}
            variant="text-s"
          />
          <TextIconBox icon="ticket" sx={{ icon: { display: 'flex' } }} text={price} variant="text-s" />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

const CarouselItemWithHref = withHref(PureCarouselItem);

export const CarouselItem: React.FC<{ program: ProgramCardProps }> = ({ program }) => {
  return (
    <SplideSlide style={{ background: 'black' }}>
      <CarouselItemWithHref href={program.externalUrl} program={program} target="_blank" />
    </SplideSlide>
  );
};
