import { assert, assertArray, assertString } from '~/gql/asserts';
import {
  CompetitionPrograms_all_competition_program_items,
  CompetitionPrograms_all_competition_program_items_price,
  CompetitionProgramsVariables,
} from '~/gql/codegen/CompetitionPrograms';
import { FeaturedEvents_all_featured_events_items_eventsConnection_edges_node } from '~/gql/codegen/FeaturedEvents';
import { getId } from '~/gql/get-id';
import { useCompetitionProgramsGql } from '~/gql/hooks/use-competition-programs-gql';
import { useArenaName } from '~/hooks/arena';
import { ProgramCardProps } from '~/pages-components/CompetitionProgramsPage/ProgramCard/ProgramCard';
import { insertBeforeLast } from '~/utils/array';
import { formatPriceObject } from '~/utils/payment';

function transformPrice(price: CompetitionPrograms_all_competition_program_items_price) {
  const { price_type, price_volume, amount_cents, price_description } = price;
  if (price_description) {
    return price_description;
  }

  if (price_type === 'legends_only') {
    return 'Legends Only';
  }

  if (price_type === 'free_to_play' || !amount_cents) {
    return 'Free';
  }

  const stringValue = formatPriceObject({ amount: amount_cents, currency: 'usd' }, { isMinorUnit: true });

  return price_volume === 'team' ? `${stringValue} per team` : `${stringValue} per person`;
}

function getLocationName(slugs: (string | null)[], slugToName) {
  if (slugs.length === 0 || slugs.includes('All Arenas') || slugs.includes('all arenas')) {
    return 'All Arenas';
  }

  if (slugs.length === 1) {
    return slugToName(slugs[0]);
  }

  return 'Multiple Arenas';
}

function getLocationInfo(slugs: (string | null)[], info: string, slugToName) {
  const arenasNames = slugs.map((slug) => slugToName(slug));

  return arenasNames.length > 1
    ? `${insertBeforeLast(arenasNames, '&')}. ${assert(info, 'info')}`
    : assert(info, 'info');
}

export function transformProgram(
  item:
    | CompetitionPrograms_all_competition_program_items
    | FeaturedEvents_all_featured_events_items_eventsConnection_edges_node,
  slugToName,
): ProgramCardProps {
  const {
    title,
    start,
    end,
    price,
    imageConnection,
    external_url,
    description,
    games,
    event_typesConnection,
    is_visible,
    location,
  } = item;

  const info = location?.info ?? '';
  const slugs = location?.arenas_slugs ?? [];

  return {
    title: assert(title, 'title'),
    location: {
      name: getLocationName(slugs, slugToName),
      info: getLocationInfo(slugs, info, slugToName),
    },
    startDate: assertString(start, 'start'),
    endDate: assertString(end, 'end'),
    events: [],
    image: { url: assert(imageConnection?.edges?.[0]?.node?.url, 'image url') },
    price: transformPrice(assert(price)),
    externalUrl: assert(external_url, 'external_url'),
    id: getId(item) as any,
    tags: assertArray(event_typesConnection?.edges?.map((x) => x?.node?.title)),
    text: assert(description, 'description'),
    registrationDeadline: 'xxxx',
    isVisible: !!is_visible,
    games: games?.all_games
      ? 'all-games'
      : assert(
          games?.gamesConnection?.edges?.map((e) => ({
            name: assert(e?.node?.title, 'game title'),
            url: assert(e?.node?.imageConnection?.edges?.[0]?.node?.url, 'game image url'),
            id: assert(e?.node?.system?.uid as any),
          })),
          'games list',
        ),
  };
}

export function useCompetitionPrograms(args: CompetitionProgramsVariables) {
  const { slugToName } = useArenaName();
  const { data, ...rest } = useCompetitionProgramsGql(args);
  return {
    data: data?.all_competition_program?.items?.map((program) => transformProgram(assert(program), slugToName)),
    ...rest,
  };
}
