import { useMemo } from 'react';

import { ArenaLocateArgs } from '~/apis';
import { arenasLocateQuery, arenasQuery } from '~/hooks/api';

export function useArenasSearch(text: string, coords: { latitude: number; longitude: number }) {
  const { latitude, longitude } = coords;

  const locationSearchArgs: ArenaLocateArgs = {
    searchType: 'POINT',
    searchText: '',
    country: '',
    latitude,
    longitude,
    radius: 50000,
  };

  const textSearchArgs: ArenaLocateArgs = {
    searchType: 'TEXT',
    searchText: text,
    country: 'USA',
    latitude: 0,
    longitude: 0,
    radius: 1000000,
  };

  // backend throws Internal Server Error (currently) when entered text yields zero arenas
  // this error is hidden from user by printing it in console.error instead of error toaster
  // when backend is fixed, `{ onError: console.error }` option should be removed
  return arenasLocateQuery.use(text ? textSearchArgs : locationSearchArgs, { onError: console.error });
}

export function useArenaName() {
  const { data } = arenasQuery.use({});
  const map = useMemo(() => {
    const map: Record<string, string> = {};
    for (const arena of data?.items ?? []) {
      map[arena.slug.toLowerCase()] = arena.name;
    }
    return map;
  }, [data?.items]);

  return {
    slugToName(slug: string) {
      return map[slug.toLowerCase().trim()];
    },
  };
}
