import '@splidejs/splide/css/core';

import { Splide } from '@splidejs/react-splide';

import { AnchorButton } from '~/components/input';
import { ProgramCardProps } from '~/pages-components/CompetitionProgramsPage/ProgramCard/ProgramCard';
import { FlexLayout } from '~/ui';

import { CarouselItem } from './CarouselItem';

function sortProgramsByStartDate(a: ProgramCardProps, b: ProgramCardProps) {
  return new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf();
}

export const Carousel = ({ data }: { data: ProgramCardProps[] }) => {
  return (
    <FlexLayout alignItems="center" flexDirection="column" space={6}>
      <Splide
        options={{
          perPage: 3,
          height: 'auto',
          rewind: true,
          gap: '32px',
          arrows: false,
          width: '100%',
          breakpoints: {
            768: {
              perPage: 1,
            },
          },
        }}
      >
        {data.sort(sortProgramsByStartDate).map((program) => (
          <CarouselItem key={program.id} program={program} />
        ))}
      </Splide>
      <AnchorButton href="/events" text="Discover More Events" variant="secondary" />
    </FlexLayout>
  );
};
