import { assert } from '~/gql/asserts';
import { useFeaturedEventsGql } from '~/gql/hooks/use-featured-events-gql';
import { useArenaName } from '~/hooks/arena';
import { transformProgram } from '~/pages-components/CompetitionProgramsPage/hooks';

export function useFeaturedEvents() {
  const { slugToName } = useArenaName();
  const { data, ...rest } = useFeaturedEventsGql();

  const programsNodes = data?.all_featured_events?.items?.[0]?.eventsConnection?.edges;

  if (!programsNodes) {
    return {
      data: programsNodes ?? [],
      ...rest,
    };
  }

  return {
    data: Object.values(programsNodes).map((program) => transformProgram(assert(program?.node), slugToName)),
    ...rest,
  };
}
