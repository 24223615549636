export function assert<T>(x: T | undefined | null, name?: string): T {
  if (x === undefined || x === null) {
    throw new Error(`Variable ${name ?? ''} cannot be optional`);
  }

  return x;
}

export function assertArray<T>(xs: Array<T | undefined | null> | undefined | null, name?: string) {
  return assert(xs, name).map((x) => assert(x, name));
}

export function assertString(x: any, name?: string) {
  if (typeof x !== 'string') {
    throw new Error(`Variable ${name ?? ''} must be a string`);
  }

  return x;
}
