import { CompetitionProgramWhere } from '~/gql/codegen/globalTypes';
import { GamePageFilter } from '~/pages-components/CompetitionProgramsPage/Filters';
import { formatISODateTime } from '~/utils/date';
import { capitalizeWordsWithChar } from '~/utils/string';

import { ProgramCardProps } from './ProgramCard/ProgramCard';

export const programsFilterToWhereArg = (filters: GamePageFilter) => {
  const where: CompetitionProgramWhere = { AND: [] };

  const { location, game, price, eventType, date } = filters;
  if (location) {
    where.AND?.push({
      location: { arenas_slugs_in: [null, '', 'All Arenas', 'all arenas', capitalizeWordsWithChar(location, '-')] },
    });
  }

  if (game) {
    where.AND?.push({
      OR: [{ games: { games: { competition_game: { uid: game } } } }, { games: { all_games: true } }],
    });
  }

  if (price) {
    where.AND?.push({ price: { price_type: price } });
  }

  if (eventType) {
    where.AND?.push({ event_types: { competition_event_type: { value: eventType } } });
  }

  if (date) {
    const [start, end] = Array.isArray(date) ? date : [date];
    where.AND?.push({
      end_gte: start,
      start_lte: end,
    });
  }

  return where;
};

export const formatProgramDate = (startDate: string, endDate: string) => {
  const start = formatISODateTime(startDate, 'MMMM d');
  const end = formatISODateTime(endDate, 'MMMM d');

  if (start === end) {
    return start;
  }

  return `${start} - ${end}`;
};

export function sortProgramsByStartDate(a: ProgramCardProps, b: ProgramCardProps) {
  return new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf();
}
