import * as contentStackComponentsMap from '~/components/static-content/contentStackComponentsMap';
import { FlexLayout, MaxWidthWrapper } from '~/ui';

interface ContentStackV2Props {
  name: string;
  data: any;
  hasBodyPadding?: boolean;
}

export const ContentStackV2: React.FC<ContentStackV2Props> = ({ name, data, hasBodyPadding = true }) => {
  if (name === 'body') {
    return (
      <MaxWidthWrapper mb={8} px={hasBodyPadding ? 4 : 0}>
        <FlexLayout
          flexDirection="column"
          space={8}
          sx={{
            position: 'relative',
          }}
        >
          {data.map((item) => {
            const [componentName, componentData] = Object.entries(item)[0] as [string, any];
            return (
              <ContentStackV2
                data={componentData}
                key={componentData?._metadata?.uid ?? componentName}
                name={componentName}
              />
            );
          })}
        </FlexLayout>
      </MaxWidthWrapper>
    );
  }

  const ContentStackComponent = contentStackComponentsMap[name];
  if (!ContentStackComponent) {
    return null;
  }

  return <ContentStackComponent data={data} key={name} />;
};
