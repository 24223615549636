import { gql, useQuery } from '@apollo/client';

import { CompetitionPrograms, CompetitionProgramsVariables } from '~/gql/codegen/CompetitionPrograms';

const GetCompetitionProgramsQuery = gql`
  query CompetitionPrograms($where: CompetitionProgramWhere) {
    all_competition_program(where: $where, limit: 30) {
      items {
        location {
          arenas_slugs
          info
        }
        name
        description
        title
        system {
          uid
        }
        start
        end
        price {
          amount_cents
          price_type
          price_volume
          price_description
        }
        event_typesConnection {
          edges {
            node {
              ... on CompetitionEventType {
                value
                title
              }
            }
          }
        }
        games {
          all_games
          gamesConnection(limit: 10) {
            edges {
              node {
                ... on CompetitionGame {
                  title
                  system {
                    uid
                  }
                  imageConnection {
                    edges {
                      node {
                        ... on SysAsset {
                          url
                          system {
                            uid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        imageConnection {
          edges {
            node {
              system {
                uid
              }
              url
            }
          }
        }
        external_url
        is_visible
      }
    }
  }
`;

export function useCompetitionProgramsGql(variables: CompetitionProgramsVariables) {
  return useQuery<CompetitionPrograms, CompetitionProgramsVariables>(GetCompetitionProgramsQuery, {
    variables,
  });
}
